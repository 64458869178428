import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import LoadingDialog from "../commons/LoadingDialog";
import { login } from './network/Login'
import { Message } from 'shineout'

const styles = theme => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.common.white,
		},
	},
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
});

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			isDone: false,
			username: '',
			password: '',
			loadingMessage: "Verifying credentials, Please wait",
			status: 0
		}
		this.handleSubmit = this.handleSubmit.bind()
	}

	handleSubmit = event => {
		event.preventDefault();
		this.setState({ isLoading: true });
		this.setState({ isDone: false });

		login({
			username: this.state.username,
			password: this.state.password,
		}, result => {
			if (result != null) {
				console.log(result);
				this.setState({ status: 200 });
				this.setState({ token: result.token });
				this.setState({ version: result.version });
				this.setState({ isDone: true });

				Message.success('Login done', 3, { position: 'bottom-right' });

				this.props.onLogin({
					username: this.state.username,
					token: this.state.token,
					version: this.state.version,
				});

			} else {
				this.setState({ status: 400 });
				this.setState({ isLoading: false });
				Message.error('Login failed', 3, { position: 'bottom-right' });
				this.setState({ isDone: true });
			}
		})
	}

	render() {
		const { classes } = this.props;
		return (
			<Container component="main" maxWidth="xs">
				<LoadingDialog
					open={this.state.isLoading}
					status={this.state.isDone}
					message={this.state.loadingMessage} />
				<CssBaseline />
				<div className={classes.paper}>
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						Sign in
                    </Typography>
					<form className={classes.form} noValidate>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							type="username"
							onChange={event => this.setState({ username: event.target.value })}
							id="username"
							label="username"
							autoComplete="word"
							autoFocus
						/>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							onChange={event => this.setState({ password: event.target.value })}
							label="Password"
							type="password"
							id="password"
							autoComplete="current-password"
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							onClick={this.handleSubmit}
							className={classes.submit}
						>Login</Button>
					</form>
				</div>
			</Container>

		)
	}
}
export default withStyles(styles)(Login);
