import superagent from 'superagent';

const URL = '/media';

export const create_update = (token, media_body, callback) => {
    superagent.post(URL)
        .set('Authorization', 'Bearer ' + token)
        .send({ media_body: media_body })
        .end((err, response) => {
            if (response.status == 200) {
                callback({ success: true, projectId: response.body.projectId })
            } else {
                callback({ success: false })
            }
            console.log(JSON.stringify(response))
        });
};

export const get = (token, packageName, callback) => {
    superagent.get(URL + '/' + packageName)
        .set('Authorization', 'Bearer ' + token)
        .end((err, response) => {
            if (response.status == 200) {
                callback(response.body);
            } else {
                callback([]);
                console.log('No data available')
            }
            console.log(response);
        }
        );
};
