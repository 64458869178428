import React from "react";
import "../../App.css";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import withStyles from "@material-ui/core/styles/withStyles";
import History from "../commons/History";
const styles = (theme) => ({
	theme: theme,
	root: {
		display: "flex",
	},
});

const menu = [
	{
		text: "Projects",
		// url: "/market/projects",
		url: "/market",
		icon: (
			<img
				src={"./icons/apps.svg"}
				width={"24px"}
				height={"24px"}
				alt={"PROJECTS"}
			/>
		),
	},
	{
		text: "Content Rules",
		url: "/market/content_rules",
		icon: (
			<img
				src={"./icons/rules.svg"}
				width={"24px"}
				height={"24px"}
				alt={"CONTENT_RULES"}
			/>
		),
	},
	{
		text: "Carousels",
		url: "/market/carousels",
		icon: (
			<img
				src={"./icons/carousel.svg"}
				width={"24px"}
				height={"24px"}
				alt={"CAROUSELS"}
			/>
		),
	},
	{
		text: "About",
		url: "/market/about",
		icon: (
			<img
				src={"./icons/about.svg"}
				width={"24px"}
				height={"24px"}
				alt={"ABOUT"}
			/>
		),
	},
];

class Sidebar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			component: "",
			open: false,
		};
		console.log(this.props.cookies);
		this.handleItemClick = this.handleItemClick.bind();
	}

	handleItemClick = (url) => (event) => {
		this.setState({ url: url });
		//TODO: Route to url
		console.log(url);
		History.push(url);
	};

	render() {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				<List>
					{menu.map((item) => (
						<ListItem
							button
							key={item.text}
							onClick={this.handleItemClick(item.url)}
						>
							<ListItemIcon>{item.icon}</ListItemIcon>
							<ListItemText primary={item.text} />
						</ListItem>
					))}
				</List>
			</div>
		);
	}
}
export default withStyles(styles)(Sidebar);
