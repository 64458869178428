import React from 'react';
import '../../../../App.css';
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTable from 'material-table';
import { get, delet } from "../../network/ContentRules";
import { withCookies } from "react-cookie";
import history from '../../../commons/History';
import { Message } from 'shineout'
const styles = theme => ({
    theme: theme,
    root: {
        display: 'wrap',
    },
    container: {
        display: 'block',
        flexWrap: 'wrap',
    },
    fab: {
        margin: theme.spacing(1),
    },
    box: {
        component: 'div',
        display: 'inline',
        flexWrap: 'wrap',
    },
    card: {
        padding: theme.spacing(1),
        margin: theme.spacing(2),
    },
    textField: {
        margin: 8,
        padding: theme.spacing(1),
        width: 200,
    },
    media: {
        paddingTop: '56.25%',
    }
});

class ContentRules extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                { title: 'Rule Id', field: '_id', editable: 'never' },
                { title: 'Description', field: 'description' },
                { title: 'Enabled', field: 'isEnabled', type: 'boolean' },
            ],
            data: [],
            token: this.props.cookies.cookies.token,
            username: this.props.cookies.cookies.username,
        };
        get(this.state.token, {}, (data) => {
            console.log(data)
            this.setState({ data: data });
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <MaterialTable
                    title="ContentRules"
                    columns={this.state.columns}
                    data={this.state.data}
                    onRowClick={(event, rowData, toggleDetailPanel) => {
                        console.log(rowData)
                        history.push('/market/content_rule', { data: rowData });
                    }}
                    actions={[{
                        icon: 'add',
                        tooltip: 'Create Content rule',
                        isFreeAction: true,
                        onClick: () => {
                            history.push('/market/content_rule', { data: {} });
                        }
                    }
                    ]}
                    options={{
                        actionsColumnIndex: 100,
                        pageSize: 25,
                        pageSizeOptions: [25, 50, 100, 200],
                    }}
                    editable={{
                        onRowDelete: oldData =>
                            new Promise(resolve => {
                                delet(this.state.token, oldData._id, result => {
                                    if (result)
                                        get(this.state.token, {}, (data) => {
                                            this.setState({ data: data });
                                            Message.success('Rule deleted', 3, { position: 'bottom-right' });
                                            resolve();
                                        });
                                    else {
                                        Message.error('Failed  to delete rule. Try again', 3, { position: 'bottom-right' });
                                        resolve();
                                    }
                                })
                            }),
                    }}
                />
            </div>
        );
    }
}

export default withStyles(styles)(withCookies(ContentRules));
