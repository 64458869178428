import React from "react";
import { withCookies } from "react-cookie";
import "../../App.css";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { red } from "@material-ui/core/colors";
import withStyles from "@material-ui/core/styles/withStyles";
import Sidebar from "./Sidebar";
import { Router, Route } from "react-router-dom";
import History from "../commons/History";
import Apps from "./pages/list/Apps";
import App from "./pages/form/App";
import Release from "./pages/form/Release";
import ContentRules from "./pages/list/ContentRules";
import ContentRule from "./pages/form/ContentRule";
import Carousels from "./pages/list/Carousels";
import Carousel from "./pages/form/Carousel";
import About from "./pages/About";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Popover from "@material-ui/core/Popover";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";

const drawerWidth = 200;
const styles = (theme) => ({
	theme: theme,
	root: {
		display: "flex",
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	avatar: {
		backgroundColor: red[500],
	},
	hide: {
		display: "none",
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: "nowrap",
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(9) + 1,
		},
	},
	toolbar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: "0 8px",
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
});

class Dashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			component: "",
			open: false,
			anchorEl: null,
			openProfile: false,
		};
		console.log(this.props.cookies);
		this.handleDrawerOpen = this.handleDrawerOpen.bind();
		this.handleDrawerClose = this.handleDrawerClose.bind();
	}

	handleDrawerOpen = (event) => {
		this.setState({ open: true });
	};

	handleDrawerClose = (event) => {
		this.setState({ open: false });
	};

	render() {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				<CssBaseline />
				<AppBar
					position="fixed"
					className={clsx(classes.appBar, {
						[classes.appBarShift]: this.state.open,
					})}
				>
					<Toolbar>
						<IconButton
							color="inherit"
							aria-label="Open drawer"
							onClick={this.handleDrawerOpen}
							edge="start"
							className={clsx(classes.menuButton, {
								[classes.hide]: this.state.open,
							})}
						>
							<MenuIcon />
						</IconButton>
						<Typography variant="h6" noWrap>
							Cloudwalker Market Backend
						</Typography>
						<IconButton
							style={{
								position: "absolute",
								top: 10,
								right: 10,
							}}
							className={classes.userMenuButton}
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={(event) => {
								this.setState({ anchorEl: event.currentTarget });
								this.setState({ openProfile: true });
							}}
							color="inherit"
						>
							<AccountCircle />
						</IconButton>

						<Popover
							id={"simple-popover"}
							open={this.state.openProfile}
							anchorEl={this.state.anchorEl}
							onClose={(event) => {
								this.setState({ anchorEl: null });
								this.setState({ openProfile: false });
							}}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "center",
							}}
						>
							<Card className={classes.card}>
								<CardHeader
									avatar={
										<Avatar aria-label="user" className={classes.avatar}>
											{this.props.cookies.cookies.username[0]}
										</Avatar>
									}
									title={"Hello " + this.props.cookies.cookies.username}
								/>
								<CardActions>
									<Button
										size="small"
										color="primary"
										onClick={(event) => {
											console.log("Logout clicked");
											History.replace('/market')
											window.location.reload();
											this.props.cookies.remove("token", "/");
											this.props.cookies.remove("username", "/");
											this.props.cookies.remove("version", "/");
										}}
									>
										Logout
									</Button>
								</CardActions>
							</Card>
						</Popover>
					</Toolbar>
				</AppBar>
				<Drawer
					variant="permanent"
					className={clsx(classes.drawer, {
						[classes.drawerOpen]: this.state.open,
						[classes.drawerClose]: !this.state.open,
					})}
					classes={{
						paper: clsx({
							[classes.drawerOpen]: this.state.open,
							[classes.drawerClose]: !this.state.open,
						}),
					}}
					open={this.state.open}
				>
					<div className={classes.toolbar}>
						<IconButton onClick={this.handleDrawerClose}>
							{<ChevronLeftIcon />}
						</IconButton>
					</div>
					<Divider />
					<List>
						<Sidebar />
					</List>
					<Divider />
				</Drawer>
				<Router history={History}>
					<div className={classes.content}>
						<div className={classes.toolbar} />
						{/* <Route exact path="/market" component={Home} /> */}
						{/* <Route path="/market/projects" component={Projects} /> */}
						<Route exact path="/market" component={Apps} />
						<Route path="/market/app" component={App} />
						<Route path="/market/release" component={Release} />
						<Route path="/market/content_rules" component={ContentRules} />
						<Route path="/market/content_rule" component={ContentRule} />
						<Route path="/market/carousels" component={Carousels} />
						<Route path="/market/carousel" component={Carousel} />
						<Route path="/market/about" component={About} />
						{/* <Route path="/market/settings" component={Settings} /> */}
					</div>
				</Router>
			</div>
		);
	}
}
export default withStyles(styles)(withCookies(Dashboard));
