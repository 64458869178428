import superagent from 'superagent';

const URL = '/app';

export const create = (token, meta_body, callback) => {
    superagent.post(URL)
        .set('Authorization', 'Bearer ' + token)
        .send({ meta_body: meta_body })
        .end((err, response) => {
            if (response.status == 200) {
                callback({ success: true, package: response.body.package })
            } else {
                callback({ success: false })
            }
            console.log(JSON.stringify(response))
        });
};

export const update = (token, meta_body, callback) => {
    superagent.put(URL)
        .set('Authorization', 'Bearer ' + token)
        .send({ meta_body: meta_body })
        .end((err, response) => {
            if (response.status == 200) {
                callback({ success: true })
            } else {
                callback({ success: false })
            }
            console.log(JSON.stringify(response))
        });
};

export const get = (token, query, callback) => {
    superagent.get(URL)
        .set('Authorization', 'Bearer ' + token)
        .query(query)
        .end((err, response) => {
            if (response.status == 200) {
                callback(response.body);
            } else {
                callback([]);
                console.log('No data available')
            }
            console.log(response);
        }
        );
};

export const delet = (token, packageName, callback) => {
    superagent.delete(URL + '/' + packageName)
        .set('Authorization', 'Bearer ' + token)
        .end((err, response) => {
            if (response.status == 200) {
                callback(true)
            } else {
                callback(false)
            }
            console.log(JSON.stringify(response))
        });
};
