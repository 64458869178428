import React from 'react';
import '../../App.css';
import { withStyles } from "@material-ui/core";
import Login from "./Login";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";

const styles = theme => ({
    menuButton: {
        marginRight: theme.spacing(1),
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
});

class Auth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            component: <Login
                onForgotPassword={this.handleDoForgotPassword}
                onRegister={this.handleDoRegister}
                onLogin={this.handleOnLogin}
            />,

        }

        this.handleDoForgotPassword = this.handleDoForgotPassword.bind()
        this.handleOnRegister = this.handleOnRegister.bind()
        this.handleOnLogin = this.handleOnLogin.bind()
        this.handleDoRegister = this.handleDoRegister.bind()
        this.handleDoLogin = this.handleDoLogin.bind()
        this.handleOnForgotPassword = this.handleOnForgotPassword.bind()
    }

    handleDoForgotPassword = res => {
        console.log('handleDoForgotPassword: ' + res)
        this.setState({
            component: <ForgotPassword
                onForgotPassword={this.handleDoForgotPassword}
                onRegister={this.handleDoRegister}
                onLogin={this.handleOnLogin}
            />,
        })
    }

    handleDoRegister = res => {
        console.log('handleDoRegister: ' + res)
        this.setState({
            component: <Register
                onLogin={this.handleDoLogin}
                onRegister={this.handleOnRegister}
            />,
        })
    }

    handleOnLogin = res => {
        console.log('handleOnLogin: ' + res)
        this.props.onComplete(res)
    }

    handleOnRegister = res => {
        console.log('handleOnRegister: ' + res)
    }

    handleDoLogin = res => {
        console.log('handleOnRegister: ' + res)
        this.setState({
            component: <Login
                onForgotPassword={this.handleDoForgotPassword}
                onRegister={this.handleDoRegister}
                onLogin={this.handleOnLogin}
            />,
        })
    }

    handleOnForgotPassword = res => {
        console.log('handleOnForgotPassword: ' + res)
    }

    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <AppBar position="fixed">
                        <Toolbar>
                            <Typography variant="title" color="inherit" align="center">
                                Cloudwalker Market Backend
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </header>
                <main className="App-main">
                    {this.state.component}
                </main>
                <footer className="App-footer">
                    <Box mt={2} mb={2}>
                        <Typography variant="body2" color="textSecondary" align="center">
                            {'All rights reserved at '}
                            <br />
                            <Link color="inherit" href="http://www.cloudwalker.tv">Cloudwalker Streaming Technologies, Mumbai</Link>
                        </Typography>
                    </Box>
                </footer>
            </div>
        );
    }
}
export default withStyles(styles)(Auth);
