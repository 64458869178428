import React from "react";
import Divider from "@material-ui/core/Divider";
import {
	Card,
	Message,
	Input,
	Rule,
	Form,
	Select,
	Grid,
	Switch,
	Button,
} from "shineout";
import { withCookies } from "react-cookie";
import { create, update, get } from "../../network/ContentRules";
import { get as getAllApps } from "../../network/App";
import { get as getAllReleases } from "../../network/Release";

const rules = Rule();
const styles = (theme) => ({
	theme: theme,
	root: {
		display: "wrap",
	},
	container: {
		display: "block",
		flexWrap: "wrap",
	},
	fab: {
		margin: theme.spacing(1),
	},
	box: {
		component: "div",
		display: "inline",
		flexWrap: "wrap",
	},
	card: {
		padding: theme.spacing(1),
		margin: theme.spacing(2),
	},
	textField: {
		margin: 8,
		padding: theme.spacing(1),
		width: 200,
	},
	media: {
		paddingTop: "56.25%",
	},
});

class ContentRule extends React.Component {
	constructor(props) {
		super(props);
		console.log(this.props.history.location.state.data);
		this.state = {
			token: this.props.cookies.cookies.token,
			username: this.props.cookies.cookies.username,
			ruleInfo: this.props.history.location.state.data,
			fields: [
				"emac",
				"mboard",
				"panel",
				"model",
				"version",
				"package",
				"brand",
				"cotaversion",
				"fotaversion",
				"accept_version",
				"ui_version",
			],
			field: [],
			apps: [],
			appList: [],
			distinct: {},
			releaseList: [],
			configuration: [],
		};
	}
	componentWillMount() {
		getAllApps(this.state.token, {}, (data) => {
			console.log(data);
			this.setState({ appList: data }, () => {
				var apps = [];
				data.map((app) => {
					apps.push(app.package);
				});
				this.setState({ apps: apps });
			});
		});
	}

	renderEmptyRule = (onAppend) => (
		<Button onClick={() => onAppend({})}>Add Rule</Button>
	);
	renderEmptyConfiguration = (onAppend) => (
		<Button onClick={() => onAppend({})}>Add Configuration</Button>
	);
	render() {
		return (
			<React.Fragment>
				<Card fullWidth>
					<Card.Header>
						Content rule defination
						<Card.Submit
							ariaLabel="SpeedDial openIcon"
							style={{
								position: "absolute",
								top: 10,
								right: 120,
							}}
						>
							Submit
						</Card.Submit>
					</Card.Header>
					<Card.Body>
						<Form
							value={this.state.ruleInfo}
							scrollToError={30}
							onSubmit={(data) => {
								console.log(data)
								if (!this.state.ruleInfo._id) {
									create(this.state.token, data,
										(result) => {
											console.log(result)
											if (result.success) {
												data._id = result._id
												this.setState({ ruleInfo: data });
												Message.success("Content rule saved", 3, {
													position: "bottom-right",
												});
											} else {
												Message.error("Failed saving changes. Try again", 3, {
													position: "bottom-right",
												});
											}
										}
									);
								} else {
									data._id = this.state.ruleInfo._id
									update(this.state.token, data,
										(result) => {
											console.log(result)
											if (result) {
												Message.success("Content rule updated", 3, {
													position: "bottom-right",
												});
											} else {
												Message.error("Failed saving changes. Try again", 3, {
													position: "bottom-right",
												});
											}
										}
									);
								}
							}}
						>
							<Grid gutter={2}>
								<Grid width={0.8}>
									<Form.Item label="Description">
										<Input name="description" />
									</Form.Item>
								</Grid>
								<Grid width={0.2}>
									<Form.Item label="Enabled">
										<Switch name="isEnabled" />
									</Form.Item>
								</Grid>
								<h3>Rules</h3>
								<Form.Item required style={{ marginTop: "16px" }}>
									<Form.FieldSet
										rules={[rules.min(1), rules.isExist]}
										name="rules"
										empty={this.renderEmptyRule}
									>
										{({ onAppend, onRemove }) => (
											<Grid
												gutter={1}
												style={{
													display: "flex",
													alignItems: "center",
													marginBottom: 12,
												}}
											>
												<Grid width={0.25}>
													<Form.Item required label="Field">
														<Select
															name="field"
															fullWidth
															data={this.state.fields}
															clearable
															onCreate
															keygen
															single
														/>
													</Form.Item>
												</Grid>

												<Grid width={0.25}>
													<Form.Item required label="Operator">
														<Select
															name="operator"
															fullWidth
															data={["IN", "NIN"]}
															clearable
															keygen
															single
														/>
													</Form.Item>
												</Grid>
												<Grid width={0.40}>
													<Form.Item required label="Values">
														<Select
															name="values"
															fullWidth
															onCreate
															clearable
															keygen
															onFilter={(text) => (d) =>
																d.toLowerCase().indexOf(text.toLowerCase()) >= 0}
															multiple
														/>
													</Form.Item>
												</Grid>
												<Grid width={0.1}>
													<a
														style={{ margin: "0 12px" }}
														onClick={() => onAppend({})}
													>
														<i className="material-icons">add</i>
													</a>
													<a onClick={onRemove}>
														<i className="material-icons">close</i>
													</a>
												</Grid>
												<Divider />
											</Grid>
										)}
									</Form.FieldSet>
								</Form.Item>
								<h3>Configuration</h3>
								<Form.Item required style={{ marginTop: "16px" }}>
									<Form.FieldSet
										rules={[rules.min(1), rules.isExist]}
										name="configuration"
										empty={this.renderEmptyConfiguration}
									>
										{({ value, onAppend, onRemove }) => (
											<Grid
												gutter={1}
												style={{
													display: "flex",
													alignItems: "center",
													marginBottom: 12,
												}}
											>
												<Grid width={0.45}>
													<Form.Item required label="Package name">
														<Select
															name="package"
															style={{ marginRight: 8 }}
															data={this.state.apps}
															onChange={(value) => {
																getAllReleases(
																	this.state.token,
																	{ package: value },
																	(data) => {
																		var releaseList = [];
																		data.map((release) =>
																			releaseList.push(release.whatsNew + " | " + release.versionCode + " |" + release._id)
																		);
																		this.setState({
																			releaseList: releaseList,
																		});
																	}
																);
															}}
															clearable
															keygen
															single
															rules={[rules.min(1), rules.isExist]}
														/>
													</Form.Item>
												</Grid>
												<Grid width={0.45}>
													<Form.Item required label="Blocked">
														<Switch
															name="isBlocked"
															defaultValue={false}
														/>
													</Form.Item>
												</Grid>
												<Grid width={0.45}>
													<Form.Item required label="Is Latest">
														<Switch
															name="isLatest"
															defaultValue={false}
														/>
													</Form.Item>
												</Grid>
												<Grid width={0.45}>
													<Form.Item label="Version code">
														<Select
															name="versionCode"
															style={{ marginRight: 8 }}
															data={this.state.releaseList}
															disabled={(value.isBlocked || value.isLatest)}
															clearable
															keygen
															single
														/>
													</Form.Item>
												</Grid>
												<Grid width={0.45}>
													<Form.Item required label="Action">
														<Select
															name="action"
															data={[
																"install",
																"uninstall",
																"upgrade",
																"downgrade",
															]}
															disabled={value.isBlocked}
															clearable
															keygen
															single
														/>
													</Form.Item>
												</Grid>
												<Grid width={0.45}>
													<Form.Item required label="Silent operation">
														<Switch name="isSilent"
															defaultValue={false}
															disabled={value.isBlocked}
														/>
													</Form.Item>
												</Grid>
												<Grid width={0.45}>
													<Form.Item required label="Hide app">
														<Switch name="isHidden"
															disabled={value.isBlocked}
															defaultValue={false} />
													</Form.Item>
												</Grid>
												<Grid width={0.45}>
													<Form.Item required label="Open Button">
														<Switch
															name="isOpenable"
															defaultValue={false}
															disabled={value.isBlocked || value.isHidden}
														/>
													</Form.Item>
												</Grid>
												<Grid width={0.45}>
													<Form.Item required label="Uninstall Button">
														<Switch
															name="isUninstallable"
															defaultValue={false}
															disabled={value.isBlocked || value.isHidden}
														/>
													</Form.Item>
												</Grid>
												<Grid width={0.45}>
													<Form.Item required label="Clear data">
														<Switch
															name="clearData"
															defaultValue={false}
															disabled={value.isBlocked}
														/>
													</Form.Item>
												</Grid>
												<Grid width={0.45}>
													<Form.Item required label="Reboot on complete">
														<Switch
															name="reboot"
															defaultValue={false}
															disabled={value.isBlocked}
														/>
													</Form.Item>
												</Grid>
												<Grid width={0.45}>
													<Form.Item required label="Uninstall First">
														<Switch
															name="uninstallFirst"
															defaultValue={false}
															disabled={value.isBlocked}
														/>
													</Form.Item>
												</Grid>
												<Grid width={0.1}>
													<a
														style={{ margin: "0 12px" }}
														onClick={() => onAppend({})}
													>
														<i className="material-icons">add</i>
													</a>
													<a onClick={onRemove}>
														<i className="material-icons">close</i>
													</a>
												</Grid>
												<Divider />
											</Grid>
										)}
									</Form.FieldSet>
								</Form.Item>
							</Grid>
						</Form>
					</Card.Body>
				</Card>
			</React.Fragment>
		);
	}
}

export default withCookies(ContentRule);
