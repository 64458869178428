import superagent from "superagent";

const URL = "/login";

export const login = (credentials, callback) => {
	superagent
		.post(URL)
		.send(credentials)
		.end((err, response) => {
			console.log(response);
			if (err) {
				callback(null);
				console.log("Request failed");
			} else {
				if (response.status === 200) {
					response.body.version = response.headers.version;
					callback(response.body);
				} else {
					callback(null);
				}
			}
		});
};
