import React from "react";
import { withCookies } from "react-cookie";

import { Form, Input, Rule, Card, Switch, Message, Upload, } from "shineout";
import { create_update } from "../../network/Carousel";

const HOST = "";
const IMAGE_UPLOAD_URL = HOST + "/upload";
const rules = Rule();

class Carousel extends React.Component {
	constructor(props) {
		super(props);
		console.log(this.props.history.location.state.data);
		this.state = {
			token: this.props.cookies.cookies.token,
			username: this.props.cookies.cookies.username,
			carousel: this.props.history.location.state.data,
		};
	}
	componentWillMount() {
		if (this.state.carousel) {
			this.state.carousel.image = [this.state.carousel.image];
			this.setState({ carousel: this.state.carousel })
		}
	}

	render() {
		return (
			<React.Fragment>
				<Card fullWidth>
					<Card.Header>
						Carousel Details
						<Card.Submit
							style={{
								position: "absolute",
								top: 10,
								right: 120,
							}}
						>
							Submit
						</Card.Submit>
					</Card.Header>
					<Card.Body>
						<Form
							value={this.state.carousel}
							onSubmit={(data) => {
								console.log(data);
								data.image = ("" + data.image[0]).replace(HOST, "")

								create_update(this.state.token, data,
									(result) => {
										if (result.success) {
											data.image = [result.data.image]
											this.setState({ carousel: data });
											this.setState({ _id: result.data._id });
											Message.success("Operation successful", 3, {
												position: "bottom-right",
											});
										} else {
											Message.error("Failed creating release. Try again", 3, {
												position: "bottom-right",
											});
										}
									}
								);
							}}
						>
							<Form.Item required label="Carousel Image">
								<Upload.Image
									action={IMAGE_UPLOAD_URL}
									accept="image/webp"
									name="image"
									value={this.state.image}
									headers={{ Authorization: "Bearer " + this.state.token }}
									htmlName={"file"}
									onSuccess={(res, file, data) => {
										this.setState({ image: [HOST + JSON.parse(res).path] });
										return HOST + JSON.parse(res).path;
									}}
									render={(children, width, height) => {
										console.log(children);
										console.log(width);
										console.log(height);
									}}
									renderResult={(f) => f}
									limit={1}
									width={150}
									height={150}
									validator={{
										imageSize: (img) =>
											img.width !== 1770 || img.height !== 498
												? new Error("Only 1770px X 498px size is allowed")
												: undefined,
										ext: (ext) =>
											["webp", "gif"].includes(ext)
												? undefined
												: new Error("File extension must be webp or gif"),
									}}
								>
									<Upload.Dragger>
										<i className="material-icons">cloud_upload</i>
										<br />
										Click or Drag image to upload
										<br />
										Carousel Image
										<br />
										Size: 1770px X 498px
									</Upload.Dragger>
								</Upload.Image>
							</Form.Item>
							<Form.Item required label="Package">
								<Input
									name="package"
									rules={[rules.required, rules.isExist]}
								/>
							</Form.Item>
							<Form.Item required label="Target">
								<Input
									name="target"
									rules={[rules.required, rules.isExist]}
								/>
							</Form.Item>
							<Form.Item required label="Index">
								<Input
									name="index"
									type="number"
									rules={[rules.required, rules.isExist]}
								/>
							</Form.Item>
							<Form.Item required label="Enable">
								<Switch
									name="isEnabled"
									rules={[rules.required, rules.isExist]}
								/>
							</Form.Item>
						</Form>
					</Card.Body>
				</Card>
			</React.Fragment >
		);
	}
}

export default withCookies(Carousel);
