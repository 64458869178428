import React from "react";
import history from "../../../commons/History";
import { withCookies } from "react-cookie";
import MaterialTable from "material-table";
import {
	Form,
	Input,
	Rule,
	Select,
	Card,
	Grid,
	Message,
	Tabs,
	Upload,
	Switch
} from "shineout";
import { update } from "../../network/App";
import { create_update, get as getMedia } from "../../network/Media";
import { get as getAllReleases, delet as deleteRelease } from "../../network/Release";

const HOST = "";
const IMAGE_UPLOAD_URL = HOST + "/upload";
const rules = Rule();

class App extends React.Component {
	constructor(props) {
		super(props);
		console.log(this.props.history.location.state.data);
		this.state = {
			token: this.props.cookies.cookies.token,
			username: this.props.cookies.cookies.username,
			app_data: this.props.history.location.state.data,
			media_data: [],
			release_data: [],
		};
	}

	componentDidMount() {
		getAllReleases(this.state.token, { package: this.state.app_data.package }, (data) => {
			console.log(data);
			this.setState({ release_data: data });
		});
		getMedia(this.state.token, this.state.app_data.package, (data) => {
			console.log(data);
			this.setState({
				media_data: {
					hiRes: [data.hiRes],
					featureGraphic: [data.featureGraphic],
					tvBanner: [data.tvBanner],
					screenshots: data.screenshots,
				}
			});
		});
	}

	render() {
		return (
			<React.Fragment>
				<Tabs defaultActive={0}>
					<Tabs.Panel style={{ padding: "12px 0" }} tab="App Details">
						<Card fullWidth>
							<Card.Header>
								App Details
								<Card.Submit
									ariaLabel="SpeedDial openIcon"
									style={{
										position: "absolute",
										top: 10,
										right: 120,
									}}
								>
									Submit
								</Card.Submit>
							</Card.Header>
							<Card.Body>
								<Form
									value={this.state.app_data}
									scrollToError={30}
									onSubmit={(data) => {
										console.log(data);
										data.old_package = this.state.app_data.package
										update(this.state.token, data, (result) => {
											if (result.success) {
												Message.success("Meta saved", 3, {
													position: "bottom-right",
												});
												this.setState({ app_data: data })
											} else {
												Message.error("Failed saving changes. Try again", 3, {
													position: "bottom-right",
												});
											}
										});
									}}
								>
									<Grid gutter={2}>
										<Grid width={1 / 2}>
											<Form.Item required label="Title">
												<Input
													name="title"
													title="Title"
												/>
											</Form.Item>
										</Grid>
										<Grid width={1 / 2}>
											<Form.Item required label="Package Name">
												<Input
													name="package"
													title="Package Name"
												/>
											</Form.Item>
										</Grid>
										<Grid width={1 / 2}>
											<Form.Item required label="Short Description">
												<Input
													name="shortDesc"
													title="Short Description"
													rules={[rules.required, rules.isExist]}
												/>
											</Form.Item>
										</Grid>
										<Grid width={1 / 2}>
											<Form.Item required label="Detailed Description">
												<Input
													name="fullDesc"
													title="Detailed Description"
												/>
											</Form.Item>
										</Grid>
										<Grid width={1 / 2}>
											<Form.Item label="Video link">
												<Input
													name="video"
													title="Video"
												/>
											</Form.Item>
										</Grid>
										<Grid width={1 / 2}>
											<Form.Item required label="Device">
												<Select
													name="device"
													data={deviceTypes}
													rules={[rules.required, rules.isExist]}
													clearable
													keygen
													onFilter={(text) => (d) => d.indexOf(text) >= 0}
													single
												/>
											</Form.Item>
										</Grid>
										<Grid width={1 / 2}>
											<Form.Item required label="App type">
												<Select
													name="appType"
													data={categories}
													onChange={(data) => {
														if (data == "Application") {
															activeList = applicationType;
														} else {
															activeList = gameType;
														}
													}}
													rules={[rules.required, rules.isExist]}
													clearable
													keygen
													onFilter={(text) => (d) => d.indexOf(text) >= 0}
													single
												/>
											</Form.Item>
										</Grid>
										<Grid width={1 / 2}>
											<Form.Item required label="Category">
												<Select
													name="category"
													data={activeList}
													rules={[rules.required, rules.isExist]}
													clearable
													keygen
													onFilter={(text) => (d) => d.indexOf(text) >= 0}
													single
												/>
											</Form.Item>
										</Grid>
										<Grid width={1 / 2}>
											<Form.Item required label="Author name">
												<Input
													name="authorName"
													title="Author name"
													rules={[rules.required, rules.isExist]}
												/>
											</Form.Item>
										</Grid>
										<Grid width={1 / 2}>
											<Form.Item required label="Author email">
												<Input
													name="authorEmail"
													title="Author email"
													rules={[rules.required, rules.isExist]}
												/>
											</Form.Item>
										</Grid>
										<Grid width={1 / 2}>
											<Form.Item required label="Author website">
												<Input
													name="authorWebsite"
													title="Author website"
													rules={[rules.required, rules.isExist]}
												/>
											</Form.Item>
										</Grid>
										<Grid width={1 / 2}>
											<Form.Item label="Phone">
												<Input
													name="phone"
													title="Phone"
												/>
											</Form.Item>
										</Grid>
										<Grid width={1 / 2}>
											<Form.Item required label="Privacy Policy">
												<Input
													name="privacyPolicy"
													title="Privacy policy"
													rules={[rules.required, rules.isExist]}
												/>
											</Form.Item>
										</Grid>
										<Grid width={1 / 2}>
											<Form.Item required label="License">
												<Input
													name="license"
													title="License"
													rules={[rules.required, rules.isExist]}
												/>
											</Form.Item>
										</Grid>
										<Grid width={1 / 2}>
											<Form.Item required label="Publish state">
												<Switch
													name="isPublished"
												/>
											</Form.Item>
										</Grid>
										<Grid width={1 / 2}>
											<Form.Item required label="Grid Index">
												<Input
													name="index"
													title="Grid Index"
													rules={[rules.required, rules.isExist]}
												/>
											</Form.Item>
										</Grid>
									</Grid>
								</Form>
							</Card.Body>
						</Card>
					</Tabs.Panel>
					<Tabs.Panel style={{ padding: "12px 0" }} tab="Media">
						<Card fullWidth>
							<Card.Header>
								App Graphics
								<Card.Submit
									ariaLabel="SpeedDial openIcon"
									style={{
										position: "absolute",
										top: 10,
										right: 120,
									}}
								>
									Submit
								</Card.Submit>
							</Card.Header>
							<Card.Body>
								<Form
									scrollToError={30}
									value={this.state.media_data}
									onSubmit={(data) => {
										if (this.state.app_data.package) {
											data.package = this.state.app_data.package;
										}
										data.hiRes.map((element, index) => {
											data.hiRes[index] = element.replace(HOST, "");
										});
										data.featureGraphic.map((element, index) => {
											data.featureGraphic[index] = element.replace(HOST, "");
										});
										data.tvBanner.map((element, index) => {
											data.tvBanner[index] = element.replace(HOST, "");
										});
										data.screenshots.map((element, index) => {
											data.screenshots[index] = element.replace(HOST, "");
										});
										create_update(this.state.token, data, (result) => {
											if (result.success) {
												Message.success("Media saved", 3, {
													position: "bottom-right",
												});
												this.setState({ media_data: data })
											} else {
												Message.error("Failed saving changes. Try again", 3, {
													position: "bottom-right",
												});
											}
										});
									}}
								>
									<Form.Item required label="Hi-res icon">
										<Upload.Image
											action={IMAGE_UPLOAD_URL}
											accept="image/webp"
											name="hiRes"
											headers={{ Authorization: "Bearer " + this.state.token }}
											htmlName={"file"}
											onSuccess={(res, file, data) => {
												console.log(res);
												return HOST + JSON.parse(res).path;
											}}
											renderResult={(f) => f}
											limit={1}
											width={150}
											height={150}
											validator={{
												imageSize: (img) =>
													img.width !== 375 || img.height !== 210
														? new Error("Only 375px X 210px size is allowed")
														: undefined,
												ext: (ext) =>
													["webp", "gif"].includes(ext)
														? undefined
														: new Error("File extension must be webp or gif"),
											}}
										>
											<Upload.Dragger>
												<i className="material-icons">cloud_upload</i>
												<br />
												Click or Drag image to upload
												<br />
												Hi-res Icon
												<br />
												Size: 375 X 210
											</Upload.Dragger>
										</Upload.Image>
									</Form.Item>
									<Form.Item required label="Feature graphic">
										<Upload.Image
											action={IMAGE_UPLOAD_URL}
											accept="image/webp"
											name="featureGraphic"
											headers={{ Authorization: "Bearer " + this.state.token }}
											htmlName={"file"}
											onSuccess={(res, file, data) => {
												console.log("RES: " + res);
												return HOST + JSON.parse(res).path;
											}}
											renderResult={(f) => f}
											limit={1}
											width={150}
											height={150}
											validator={{
												imageSize: (img) =>
													img.width % 16 !== 0 || img.height % 9 !== 0
														? new Error("Only 16:9 Aspect ratio is allowed")
														: undefined,
												ext: (ext) =>
													["webp", "gif"].includes(ext)
														? undefined
														: new Error("File extension must be webp or gif"),
											}}
										>
											<Upload.Dragger>
												<i className="material-icons">cloud_upload</i>
												<br />
												Click or Drag image to upload
												<br />
												Feature Graphic
												<br />
												Size: 16:9 Aspect Ratio
											</Upload.Dragger>
										</Upload.Image>
									</Form.Item>
									<Form.Item required label="TV Banner">
										<Upload.Image
											action={IMAGE_UPLOAD_URL}
											accept="image/webp"
											name="tvBanner"
											headers={{ Authorization: "Bearer " + this.state.token }}
											htmlName={"file"}
											onSuccess={(res, file, data) => {
												console.log("RES: " + res);
												return HOST + JSON.parse(res).path;
											}}
											renderResult={(f) => f}
											limit={1}
											width={150}
											height={150}
											validator={{
												imageSize: (img) =>
													img.width !== 1280 || img.height !== 720
														? new Error("Only 1280px X 720px size is allowed")
														: undefined,
												ext: (ext) =>
													["webp", "gif"].includes(ext)
														? undefined
														: new Error("File extension must be webp or gif"),
											}}
										>
											<Upload.Dragger>
												<i className="material-icons">cloud_upload</i>
												<br />
												Click or Drag image to upload
												<br />
												TV Banner
												<br />
												Size: 1280 X 720
											</Upload.Dragger>
										</Upload.Image>
									</Form.Item>
									<Form.Item required label="Screenshots">
										<Upload.Image
											action={IMAGE_UPLOAD_URL}
											accept="image/*"
											name="screenshots"
											headers={{ Authorization: "Bearer " + this.state.token }}
											htmlName={"file"}
											onSuccess={(res, file, data) => {
												console.log(res);
												return HOST + JSON.parse(res).path;
											}}
											renderResult={(f) => f}
											multiple
											width={150}
											height={150}
											validator={{
												imageSize: (img) =>
													img.width % 16 !== 0 || img.height % 9 !== 0
														? new Error("Only 16:9 Aspect ratio is allowed")
														: undefined,
												ext: (ext) =>
													["webp", "gif"].includes(ext)
														? undefined
														: new Error("File extension must be webp or gif"),
											}}
										>
											<Upload.Dragger>
												<i className="material-icons">cloud_upload</i>
												<br />
												Click or Drag image to upload
												<br />
												Screenshots
												<br />
												Size: 16:9 Aspect Ratio
											</Upload.Dragger>
										</Upload.Image>
									</Form.Item>
								</Form>
							</Card.Body>
						</Card>
					</Tabs.Panel>
					<Tabs.Panel style={{ padding: "12px 0" }} tab="Release">
						<Card fullWidth>
							<Card.Header>
								Release manager
							</Card.Header>
							<Card.Body>
								<Form scrollToError={30}>
									<Form.Item>
										<MaterialTable
											title="Releases"
											columns={[
												{ title: "ID", field: "_id", editable: "never" },
												{ title: "Whats new", field: "whatsNew" },
												{ title: "Version Code", field: "versionCode" },
											]}
											data={this.state.release_data}
											onRowClick={(event, rowData, toggleDetailPanel) => {
												history.push("/market/release", { data: rowData });
											}}
											actions={[
												{
													icon: "add",
													tooltip: "Create Release",
													isFreeAction: true,
													onClick: () => {
														history.push("/market/release",
															{ data: { package: this.state.app_data.package } });
													},
												},
											]}
											options={{
												actionsColumnIndex: 100,
											}}
											editable={{
												onRowDelete: (oldData) =>
													new Promise((resolve) => {
														deleteRelease(
															this.state.token,
															oldData._id,
															(status) => {
																if (status) {
																	getAllReleases(
																		this.state.token,
																		{ package: this.state.app_data.package },
																		(data) => {
																			this.setState({ release_data: data });
																			Message.success("Release deleted", 3, {
																				position: "bottom-right",
																			});
																			resolve();
																		}
																	);
																} else {
																	Message.error("Failed deleting release", 3, {
																		position: "bottom-right",
																	});
																	resolve();
																}
															}
														);
													}),
											}}
										/>
									</Form.Item>
								</Form>
							</Card.Body>
						</Card>
					</Tabs.Panel>
				</Tabs>
			</React.Fragment>
		);
	}
}

export default withCookies(App);

const categories = ["Application", "Games"];

const deviceTypes = ["Phone", "Tab", "TV", "AirStream", "Wear OS"];

const applicationType = [
	"Art & Design",
	"Auto & Vehicles",
	"Beauty",
	"Book & Reference",
	"Business",
	"Comics",
	"Communication",
	"Dating",
	"Education",
	"Entertainment",
	"Events",
	"Finance",
	"Food & Drink",
	"Health & Fitness",
	"House & Home",
	"Libraries & Demo",
	"Lifestyle",
	"Maps & Navigation",
	"Medical",
	"Music & Audio",
	"News & Magazines",
	"Parenting",
	"Personalization",
	"Photography",
	"Productivity",
	"Shopping",
	"Social",
	"Sports",
	"Tools",
	"Travel & Local",
	"Video Players & Editors",
	"Weather",
];

var activeList = applicationType;
const gameType = [
	"Action",
	"Adventure",
	"Arcade",
	"Board",
	"Card",
	"Casino",
	"Casual",
	"Educational",
	"Music",
	"Puzzle",
	"Racing",
	"Role Playing",
	"Simulation",
	"Sports",
	"Strategy",
	"Trivia",
	"Word",
];
